import React, { useState } from "react";
import Modal from "./Modal";
import Input from "./Input";
import classes from "./EmailLogin.module.css";
import Button from "./Button";
import { logiWithEmailAndPassword, signInWithGoogle } from "../firebase/auth";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import store from "../redux/store";

export default function EmailLogin() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [Erro, setErro] = useState("");

  if (store.getState()?.timer?.user?.uid) {
    history.push("../");
  }

  const handleLogin = () => {
    if (email && password) {
      logiWithEmailAndPassword(email.toLocaleLowerCase(), password)
        .then(() => {
          history.push("../");
        })
        .catch((error) => {
          setErro("Email or password incorrect");
          setTimeout(() => {
            setErro("");
          }, 3000);
        });
    }
  };

  const loginWithGoogle = async () => {
    signInWithGoogle()
      .then((result) => {
        history.push("../");
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  return (
    <Modal className={classes.modal}>
      <div className={classes.container}>
        <h2 className={classes.title}>Please Input Your Email and Password</h2>
        {Erro && (
          <h3 className={classes.error}>
            The password or the email you entered are incorrect
          </h3>
        )}
        <div className={classes.input}>
          <Input
            placeholder="example@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={classes.input}>
          <Input
            placeholder="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <Link to="/register">
          <h5 className={classes.link}>Create Account</h5>
        </Link>

        <Link to="/forgot">
          <h5 className={classes.link}>Forgot your Password?</h5>
        </Link>

        <div className={classes.button}>
          <Button icon="login" onClick={handleLogin}>
            Login
          </Button>
        </div>

        <div onClick={loginWithGoogle} className={classes.GoogleButton}>
          <span>Login with Google</span>
        </div>
      </div>
    </Modal>
  );
}
