import { collection, Firestore, getDoc } from "firebase/firestore";
import {
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  where,
  query,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase/index";

export const PomodorosCollectionRef = collection(db, "Pomodoros");

export const FIREBASE_GetDocIDByUid = async (uid) => {
  var CollectionRef = collection(db, "Pomodoros");
  const Query = query(CollectionRef, where("user.uid", "==", uid));
  const querySnapshot = await getDocs(Query);
  const matchedDocs = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    docID: doc.id,
  }));
  const matchedDoc = matchedDocs[0];
  return matchedDoc?.docID || "SemDocID";
};

export const FIREBASE_GetDocs = async () => {
  var CollectionRef = collection(db, "Pomodoros");
  const data = await getDocs(CollectionRef);
  const dados = data.docs.map((doc) => ({ ...doc.data(), docID: doc.id }));
 // console.log("DADOS", dados);
};

//UPDATE
export const FIREBASE_Update = async (Store) => {
 // console.log("UPDATING", Store); 
  if (Store?.timer?.user?.docID) {
    updateDoc(doc(db, "Pomodoros", Store?.timer?.user?.docID), Store?.timer);
  }
};

//UPDATE
export const FIREBASE_GET = async (docID) => {
  if (!docID) return;
  const docRef = doc(db, "Pomodoros", docID);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
  }
};

export const FIREBASE_CreateUserPomodoro = async (Payload) => {
 // console.log("DEBUG CreateUserPomodoro");
  var CollectionRef = collection(db, "Pomodoros");
  return addDoc(CollectionRef, Payload);
};

// export const CreateChamado = async (NovoChamado) => {
//   colecao = "chamados";
//   usersCollectionRef = collection(db, colecao);
//   await addDoc(usersCollectionRef, NovoChamado);
// };

// //// DADOS PORTAL

// export const SetDadosPortal = async (Dados) => {
//   colecao = "dadosportal";
//   usersCollectionRef = collection(db, colecao);
//   await addDoc(usersCollectionRef, Dados);
// };

// export const getDadosPortal = async () => {
//   colecao = "dadosportal";
//   usersCollectionRef = collection(db, colecao);
//   const data = await getDocs(usersCollectionRef);
//   const dados = data.docs.map((doc) => ({ ...doc.data(), docID: doc.id }));
//   return dados;
// };

// //// DADOS PORTAL

// export const getUsuarios = async () => {
//   colecao = "usuarios";
//   usersCollectionRef = collection(db, colecao);
//   const data = await getDocs(usersCollectionRef);
//   const dados = data.docs.map((doc) => ({ ...doc.data(), docID: doc.id }));
//   SetUsuarios(dados);

//   return dados;
// };

// export const getChamados = async () => {
//   colecao = "chamados";
//   usersCollectionRef = collection(db, colecao);
//   const data = await getDocs(usersCollectionRef);
//   const dados = data.docs.map((doc) => ({
//     ...DefaultChamado,
//     ...doc.data(),
//     docID: doc.id,
//   }));
//   SetChamados(dados);

//   //QUERY
//   const q = query(usersCollectionRef, where("Andamento.Status", "!=", "BLA"));

//   const querySnapshot = await getDocs(q);
//   const dados2 = querySnapshot.docs.map((doc) => {
//     return { ...doc.data(), docID: doc.id };
//   });

//   SetChamados(dados2);
//   //QUERY

//   return dados;
// };

// export const updateUser = async (EditedUser) => {
//   colecao = "usuarios";
//   usersCollectionRef = collection(db, colecao);
//   const usuaioDoc = doc(db, colecao, EditedUser.docID);
//   const newFields = { ...EditedUser };
//   await updateDoc(usuaioDoc, newFields);
// };

// export const setListeningChamado = (docID) => {
//   //console.log("Iniciando o Listening", docID)
//   return onSnapshot(doc(db, colecao, docID), (doc) => {
//     SetChamado(doc.data());
//   });
// };

// export const stopListening = () => {};

// export const UpdateDadosPortal = async (Dados) => {
//   colecao = "dadosportal";
//   usersCollectionRef = collection(db, colecao);
//   const DadosDoc = doc(db, colecao, Dados.docID);
//   const newFields = { ...Dados };
//   await updateDoc(DadosDoc, newFields);
//   //getDadosPortal().then(DATA => {  SetDados(DATA[0])})
// };

// export const updateChamado = async (EditedChamado) => {
//   colecao = "chamados";
//   usersCollectionRef = collection(db, colecao);
//   const usuaioDoc = doc(db, colecao, EditedChamado.docID);
//   const newFields = { ...EditedChamado };
//   await updateDoc(usuaioDoc, newFields);
// };

// export const deleteAllTarefasFirebase = (tarefas, uid) => {
//   colecao = "tarefas" + uid;
//   usersCollectionRef = collection(db, colecao);
//   tarefas.map((tarefa) => {
//     const tarefaDoc = doc(db, colecao, tarefa.id);
//     deleteDoc(tarefaDoc);
//     return true;
//   });
//   SetUsuarios([]);
// };

// export const deleteTarefasConcluidasFirebase = (apenasCompletas, uid) => {
//   colecao = "tarefas" + uid;
//   usersCollectionRef = collection(db, colecao);
//   apenasCompletas.map((tarefa) => {
//     const tarefaDoc = doc(db, colecao, tarefa.id);
//     deleteDoc(tarefaDoc);
//     return true;
//   });
// };
