import { useCallback } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import Icon from "./Icon";
import classes from "./Header.module.css";
import Menu, { MenuItem } from "./Menu";
import GoogleLogo from "../assets/google-black.png";
import EmailLogo from "../assets/envelope-black.png";
import LogoutIcon from "../assets/logout.png";
import Question from "../assets/Question.png";

import LoginIcon from "../assets/login.png";
import CreateAccontIcon from "../assets/createAccount.png";
import { logout, signInWithGoogle } from "../firebase/auth";
import { connect } from "react-redux";
import getEmailPrefix from "../utils/getEmailPrefix";
import moment from "moment";

function Button({ icon, children, onClick }) {
  // const Today = "20/04/2024";

  // const days = {
  //   ["17/04/2024"]: {
  //     date: "15/04/2024",
  //     minutes: 3,
  //   },
  //   ["18/04/2024"]: {
  //     date: "18/04/2024",
  //     minutes: 3,
  //   },
  //   ["19/04/2024"]: {
  //     date: "19/04/2024",
  //     minutes: 3,
  //   },
  //   ["20/04/2024"]: {
  //     date: "20/04/2024",
  //     minutes: 3,
  //   },
  // };

  // const calculateDayStreak = (days, currentDate) => {
  //   let streak = 0;

  //   // Converte as chaves do objeto days em um array e as ordena de forma decrescente
  //   const sortedDates = Object.keys(days).sort((a, b) =>
  //     moment(b, "DD/MM/YYYY").diff(moment(a, "DD/MM/YYYY"))
  //   );

  //   // Itera sobre as datas ordenadas
  //   sortedDates.forEach((date) => {
  //     // Se a data atual for consecutiva à data na iteração atual, incrementa a streak
  //     if (
  //       moment(currentDate, "DD/MM/YYYY").diff(
  //         moment(date, "DD/MM/YYYY"),
  //         "days"
  //       ) === streak
  //     ) {
  //       streak++;
  //     }
  //   });

  //   return streak;
  // };

  //// console.log("STREAK", calculateDayStreak(days, "22/04/2024"));

  return (
    <button className={classes.button} onClick={onClick}>
      <Icon name={icon} />
      <span className={classes.label}>{children}</span>
    </button>
  );
}

const Header = (props) => {
  const GetName = () => {
    if (props?.timerSlice?.user?.displayName)
      return props?.timerSlice?.user?.displayName;
    else if (
      !props?.timerSlice?.user?.displayName &&
      props?.timerSlice?.user?.uid
    )
      return getEmailPrefix(props?.timerSlice?.user?.email);
    else return "Login";
  };
  //console.log("HEADER", props.timerSlice);

  const renderMenuButton = (onClick) => (
    <Button icon="account_circle" onClick={onClick}>
      {GetName()}
    </Button>
  );

  const loginWithGoogle = async () => {
    signInWithGoogle()
      .then((result) => {})
      .catch((error) => {
        //console.log(error);
      });
  };

  return (
    <header className={classes.container}>
      <div className={classes.content}>
        <Logo />
        <ul className={classes.nav}>
          <li>
            <Link to="/report">
              <Button icon="insert_chart_outlined">Report</Button>
            </Link>
          </li>
          <li>
            <Link to="/settings">
              <Button icon="settings">Setting</Button>
            </Link>
          </li>
          <li>
            <Menu menuButton={renderMenuButton}>
              {!props.timerSlice.user.uid && (
                <>
                  {1 !== 1 && (
                    <MenuItem src={GoogleLogo} onClick={loginWithGoogle}>
                      Login with Google
                    </MenuItem>
                  )}
                  <Link to="/login">
                    <MenuItem src={LoginIcon}>Login</MenuItem>
                  </Link>
                  <Link to="/register">
                    <MenuItem src={CreateAccontIcon}>Create Account</MenuItem>
                  </Link>
                  <a target="_blank" href="https://bkappi.com/dicas/tecnica-pomodoro-uma-opcao-simples-para-focar-e-aumentar-a-produtividade/">
                    <MenuItem src={Question}>Learn Pomodoro</MenuItem>
                  </a>
                </>
              )}

              {props.timerSlice.user.uid && (
                <MenuItem src={LogoutIcon} onClick={logout}>
                  Logout
                </MenuItem>
              )}
            </Menu>
          </li>
        </ul>
      </div>
    </header>
  );
};

const ConnectedHeader = connect((state) => {
  return {
    timerSlice: state.timer,
  };
})(Header);

export default ConnectedHeader;
