export default function getEmailPrefix(email) {
    // Verifica se o email é uma string
    if (typeof email !== 'string') {
      throw new Error('O email deve ser uma string');
    }
  
    // Divide o email em duas partes: a parte antes do "@" e a parte depois do "@"
    const [prefix, domain] = email.split('@');
  
    // Verifica se o email tem o formato correto
    if (!prefix || !domain) {
      throw new Error('O email deve estar no formato "prefixo@dominio"');
    }
  
    // Capitaliza a primeira letra do prefixo e retorna
    return prefix.charAt(0).toUpperCase() + prefix.slice(1);
  }