import { useCallback, useEffect, useRef, useState } from "react";
import store from "./redux/store";
import { incrementMinutesFocused } from "./redux/timerSlice";

export default function useCountdown({ minutes, onStart, onStop, onComplete }) {
  const timerId = useRef(null);
  //const time = minutes * 60;
  const [time, settime] = useState(minutes * 60);
  const [progress, setProgress] = useState(0);
  const [timeLeft, setTime] = useState(time);
  const [ticking, setTicking] = useState(false);
  const [seconds, setseconds] = useState(0);

  const clear = () => {
    clearInterval(timerId.current);
    timerId.current = null;
  };

  const tick = useCallback(() => {
    //console.log("COUNT", timeLeft, progress, ticking);
    if (timeLeft > 0) {
      setTime(timeLeft - 1);
      setProgress((count) => count + 1);
    }
    if (timeLeft <= 1) {
      setTicking(false);
      clear();
      onComplete?.();
    }

    setseconds((current) => {
      return current + 1;
    });
  }, [onComplete, timeLeft]);

  useEffect(() => {

    if (seconds >= 60) {
      setseconds(0);
      store.dispatch(incrementMinutesFocused());
    }
  }, [seconds]);

  useEffect(() => {
    if (ticking) {
      timerId.current = setInterval(tick, 1000);
    } else {
      clear();
    }

    return clear;
  }, [tick, ticking]);

  useEffect(() => {
    setTime(time);
  }, [time]);

  const start = useCallback(() => {
    setTicking(true);
    onStart?.();
  }, [onStart]);

  const stop = useCallback(() => {
    setTicking(false);
    onStop?.();
  }, [onStop]);

  const reset = useCallback(() => {
    setTicking(false);
    setProgress(0);
    onStop?.();
   
  }, [onStop]);

  useEffect(() => {
    settime(minutes * 60);
  }, [minutes]);

  return {
    start,
    stop,
    reset,
    seconds,
    ticking,
    timeLeft,
    progress: (progress / time) * 100,
  };
}
