import clsx from "clsx";
import { NoData, Title } from "./common";
import classes from "./Detail.module.css";
import { connect } from "react-redux";
import moment from "moment";

const rows = [
  { date: "2024-04-01", minutes: 30 },
  { date: "2024-04-02", minutes: 45 },
  { date: "2024-04-03", minutes: 60 },
];

function Table(days) {
  return (
    <table className={classes.table}>
      <tr className={clsx(classes.row, classes.headerRow)}>
        <th className={classes.header}>Date</th>
        <th className={classes.header}>Minutes</th>
      </tr>
      {days?.days &&
        Object?.keys(days?.days)
          .sort((a, b) => moment(b, "DD/MM/YYYY").diff(moment(a, "DD/MM/YYYY")))
          .map((day) => {
            return (
              <tr className={clsx(classes.row, classes.DataRow)}>
                <th className={classes.header}>{days?.days[day]?.date}</th>
                <th className={classes.cell}>{days?.days[day]?.minutes} min</th>
              </tr>
            );
          })}
    </table>
  );
}

const Detail = (props) => {
  return (
    <div>
      <Title>Focus Time Detail</Title>
      {!props.timerSlice.user.uid && <NoData />}

      <Table days={props.timerSlice.days} />
    </div>
  );
};

const ConnectedDetail = connect((state) => {
  return {
    timerSlice: state.timer,
  };
})(Detail);

export default ConnectedDetail;
