import { createSlice } from "@reduxjs/toolkit";
import {
  POMODORO,
  LONG_BREAK,
  SHORT_BREAK,
  SLOW_TICKING,
  DIGITAL_SOUND,
  NO_SOUND,
} from "../constants";

import moment from "moment";

// Função para carregar o estado inicial da store do localStorage
export const loadState = () => {
  return initialState
  try {
    const serializedState = localStorage.getItem("timerState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return initialState;
  }
};

// Função para salvar o estado da store no localStorage
export const saveTimerState = (state) => {
  // console.log("SALVANDO LOCALSTORAGE");
  try {
    const serializedState = JSON.stringify(state);
    //localStorage.setItem("timerState", serializedState);
  } catch {
    // Ignore write errors
  }
};

const initialState = {
  mode: POMODORO,
  round: 1,
  autoBreaks: false,
  autoPomodoros: false,
  longBreakInterval: 4,
  alarmSound: DIGITAL_SOUND,
  alarmVolume: 50,
  alarmRepeat: 1,
  tickingSound: NO_SOUND,
  tickingVolume: 50,
  sync: false,
  minutesFocused: 0,
  daysAccessed: 0,
  dayStreak: 0,
  days: {},
  modes: {
    [POMODORO]: {
      id: POMODORO,
      label: "Pomodoro",
      time: 25,
    },
    [SHORT_BREAK]: {
      id: SHORT_BREAK,
      label: "Short Break",
      time: 5,
    },
    [LONG_BREAK]: {
      id: LONG_BREAK,
      label: "Long Break",
      time: 15,
    },
  },
  creatingUser: false,
  user: {
    docID: "",
    uid: "",
    email: "",
    photoURL: "",
    displayName: "",
  },
};

export const calculateDayStreak = (days, currentDate) => {
  let streak = 0;

  // Converte as chaves do objeto days em um array e as ordena de forma decrescente
  const sortedDates = Object.keys(days).sort((a, b) =>
    moment(b, "DD/MM/YYYY").diff(moment(a, "DD/MM/YYYY"))
  );

  // Itera sobre as datas ordenadas
  sortedDates.forEach((date) => {
    // Se a data atual for consecutiva à data na iteração atual, incrementa a streak
    if (
      moment(currentDate, "DD/MM/YYYY").diff(
        moment(date, "DD/MM/YYYY"),
        "days"
      ) === streak
    ) {
      streak++;
    }
  });

  return streak === 0 ? 1 : streak;
};

export const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    setAll: (state, action) => {
      if (!action.payload) return;
      state.autoBreaks = action.payload.autoBreaks;
      state.mode = action.payload.mode;
      state.round = action.payload.round;
      state.autoPomodoros = action.payload.autoPomodoros;
      state.longBreakInterval = action.payload.longBreakInterval;
      state.alarmSound = action.payload.alarmSound;
      state.alarmVolume = action.payload.alarmVolume;
      state.alarmRepeat = action.payload.alarmRepeat;
      state.tickingSound = action.payload.tickingSound;
      state.tickingVolume = action.payload.tickingVolume;
      state.sync = true;
      state.modes = action.payload.modes;
      state.creatingUser = false;
      state.user = action.payload.user;
      state.dayStreak = action.payload.dayStreak;
      state.minutesFocused = action.payload.minutesFocused;
      state.days = action.payload.days;
      state.daysAccessed = action.payload.daysAccessed;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setminutesFocused: (state, action) => {
      state.minutesFocused = action.payload;
    },

    incrementMinutesFocused: (state, action) => {
      state.minutesFocused += 1;
      const date = moment().format("DD/MM/YYYY");


      if (state.days[date]) {
        state.days[date].minutes += 1;
        state.daysAccessed = Object.keys(state.days).length;
        //g("DAYS SIM", state.days[date]);
      } else {
        state.days[date] = {
          date: date,
          minutes: 1,
        };

        state.dayStreak = calculateDayStreak(state.days, date);
        state.daysAccessed = state?.days
          ? Object?.keys(state?.days)?.length
          : 0;
      }
    },

    incrementDaysAccessed: (state, action) => {
      state.daysAccessed += 1;
    },
    incrementDayStreak: (state, action) => {
      state.dayStreak += 1;
    },
    setSync: (state, action) => {
      state.sync = true;
    },
    setcreatingUser: (state, action) => {
      state.creatingUser = true;
    },
    unsetcreatingUser: (state, action) => {
      state.creatingUser = false;
    },
    incrementRound: (state) => {
      state.round += 1;
    },
    updateModeTime: (state, action) => {
      const { mode, time } = action.payload;
      state.modes[mode].time = time;
    },
    setUser: (state, action) => {
      const { uid, email, photoURL, displayName, docID } = action.payload;
      state.user = {
        uid: uid,
        email: email,
        photoURL: photoURL,
        displayName: displayName,
        docID: docID,
      };
    },
    setdocID: (state, action) => {
      state.user.docID = action.payload;
    },
    toggleAutoBreaks: (state) => {
      state.autoBreaks = !state.autoBreaks;
    },
    toggleAutoPomodoros: (state) => {
      state.autoPomodoros = !state.autoPomodoros;
    },
    setLongBreakInterval: (state, action) => {
      state.longBreakInterval = action.payload;
    },
    setAlarmSound: (state, action) => {
      state.alarmSound = action.payload;
    },
    setAlarmVolume: (state, action) => {
      state.alarmVolume = action.payload;
    },
    setAlarmRepeat: (state, action) => {
      state.alarmRepeat = action.payload;
    },
    setTickingSound: (state, action) => {
      state.tickingSound = action.payload;
    },
    setTickingVolume: (state, action) => {
      state.tickingVolume = action.payload;
    },
  },
});

export const {
  setMode,
  incrementRound,
  updateModeTime,
  toggleAutoBreaks,
  toggleAutoPomodoros,
  setLongBreakInterval,
  setAlarmSound,
  setAlarmVolume,
  setAlarmRepeat,
  setTickingSound,
  setTickingVolume,
  setUser,
  setcreatingUser,
  unsetcreatingUser,
  setdocID,
  setAll,
  setSync,
  incrementMinutesFocused,
  incrementDaysAccessed,
  incrementDayStreak,
} = timerSlice.actions;

export default timerSlice.reducer;
