import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import Settings from "./components/Settings";
import Report from "./components/Report";
import EmailLogin from "./components/EmailLogin";
import store from "./redux/store";
import EmailRegister from "./components/EmailRegister";
import Forgot from "./components/Forgot";

function Main() {
  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <Provider store={store}>
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route path="/">
            <App />
          </Route>
        </Switch>
        <Route path="/login">
          <EmailLogin />
        </Route>
        <Route path="/register">
          <EmailRegister />
        </Route>
        <Route path="/forgot">
          <Forgot />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route path="/report">
          <Report />
        </Route>
      </Router>
    </Provider>
  );
}

ReactDOM.render(<Main />, document.getElementById("root"));
