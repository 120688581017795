import React, { useState } from "react";
import Modal from "./Modal";
import Input from "./Input";
import classes from "./EmailRegister.module.css";
import Button from "./Button";
import {
  registerUserWithEmailAndPassword,
  signInWithGoogle,
} from "../firebase/auth";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import store from "../redux/store";


export default function EmailRegister() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [Message, setMessage] = useState("");

  if (store.getState()?.timer?.user?.uid) {
    history.push("../");
  }



  const handleRegister = () => {
    if (email && password && confirmPassword && password === confirmPassword) {
      registerUserWithEmailAndPassword(email.toLocaleLowerCase(), password)
        .then(async () => {
          // Registro bem-sucedido, você pode redirecionar o usuário ou fazer outra ação aqui
          setMessage("User registered successfully");

          setTimeout(() => {
            history.push("../");
          }, 3000);
        })
        .catch((error) => {
         // console.log(error);
          if (error?.code?.includes("password")) {
            setError("A senha deve ter pelo menos 6 caracteres");
            setTimeout(() => {
              setError("");
            }, 3000);
            return;
          }
          if (error?.code?.includes("already-in-use")) {
            setError("User already registered, redirecting to Login...");

            setTimeout(() => {
              setError("");
              history.push("../login");
            }, 4000);

            return;
          }
          if (error?.code?.includes("invalid-email")) {
            setError("Invalid Email");

            setTimeout(() => {
              setError("");
            }, 3000);

            return;
          }

          setError("Something went wrong, try again later");
          setTimeout(() => {
            setError("");
          }, 3000);
        });
    } else {
      setError("The passwords do not match or some field is empty");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  const loginWithGoogle = async () => {
    signInWithGoogle()
      .then((result) => {
        setMessage("User registered successfully");
        setTimeout(() => {
          setMessage("");
          history.push("../");
        }, 3000);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  return (
    <Modal className={classes.modal}>
      <div className={classes.container}>
        <h2 className={classes.title}>Create Your Account</h2>
        {error && <h3 className={classes.error}>{error}</h3>}
        {Message && <h3 className={classes.message}>{Message}</h3>}
        <div className={classes.input}>
          <Input
            placeholder="example@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={classes.input}>
          <Input
            placeholder="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className={classes.input}>
          <Input
            placeholder="confirm password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <Link to="/login">
          <h5 className={classes.link}>Already have an account?</h5>
        </Link>
        <div className={classes.button}>
          <Button icon="person" onClick={handleRegister}>
            Create Account
          </Button>
        </div>
        <div onClick={loginWithGoogle} className={classes.GoogleButton}>
          <span>Register with Google</span>
        </div>
      </div>
    </Modal>
  );
}
