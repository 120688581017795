import { configureStore } from "@reduxjs/toolkit";
import timerReducer, { loadState, saveTimerState, setAll } from "./timerSlice";
import { FIREBASE_GET, FIREBASE_Update } from "../firebase/metodos";

const store = configureStore({
  reducer: {
    timer: timerReducer,
  },
});

// Função para registrar mudanças na store no console
const logChanges = () => {
  //console.log("LOG STORE", store.getState().timer);
  //saveTimerState(store.getState().timer);

  if (!store.getState().timer.user.uid)
    saveTimerState({ ...store.getState().timer });

  if (store.getState().timer.user.docID && store.getState().timer.sync) {
    FIREBASE_Update(store.getState());
    // FIREBASE_GET(store.getState().timer.user.docID).then((DADOS) =>{
    //  // console.log("DADOS PEGOS", DADOS)
    // })
  }
};

// Inscreva a função logChanges para ser chamada sempre que houver uma mudança na store
const unsubscribe = store.subscribe(logChanges);

export default store;
