import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { auth } from "./index";
import {
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  FacebookAuthProvider,
  updatePassword,
} from "firebase/auth";

import {
  loadState,
  setAll,
  setcreatingUser,
  setdocID,
  setSync,
  setUser,
} from "../redux/timerSlice";
import store from "../redux/store";
import {
  FIREBASE_CreateUserPomodoro,
  FIREBASE_GET,
  FIREBASE_GetDocIDByUid,
} from "./metodos";

onAuthStateChanged(auth, (currentUser) => {
  //console.log("LOG AUTHCHANGED", currentUser ? currentUser : "VAZIO");

  const user = {
    email: currentUser?.email || "",
    uid: currentUser?.uid || "",
    photoURL: currentUser?.photoURL || "",
    displayName: currentUser?.displayName || "",
    docID: "",
  };

  store.dispatch(setUser(user));

  if (store.getState().timer.user.uid) {
    FIREBASE_GetDocIDByUid(user.uid).then((docID) => {
      if (docID === "SemDocID") {
       // console.log("Usuário precisa ser Registrado", store.getState().timer);
        if (
          !store.getState().timer.user.docID &&
          store.getState().timer.user.docID !== "SemDocID"
        ) {
          FIREBASE_CreateUserPomodoro(store.getState().timer)
            .then((data) => {
             // console.log("Usuário Registrado", data.id);
              store.dispatch(setdocID(data.id));
              FIREBASE_GET(data.id).then((DADOS) => {
                //// console.log("DADOS NOVOS", {
                //   ...DADOS,
                //   user: { ...user, docID: data.id },
                // });
                //store.dispatch(setdocID(docID));
                const NewData = { ...DADOS, user: { ...user, docID: data.id } };
                store.dispatch(setAll(NewData));
              });
            })
            .catch((Erro) => {
             // console.log("Erro ao registrar", Erro);
            });
        }
      } else {
       // console.log("Usuário já Registrado", docID);
        FIREBASE_GET(docID).then((DADOS) => {
          //// console.log("DADOS NOVOS", {
          //   ...DADOS,
          //   user: { ...user, docID: docID },
          // });
          //store.dispatch(setdocID(docID));
          const NewData = { ...DADOS, user: { ...user, docID: docID } };
          store.dispatch(setAll(NewData));
        });
      }
    });
  } else {
   // console.log("LOADING SEM USER")
    store.dispatch(setAll(loadState()))
  }
});

export const mudarSenha = async (novaSenha) => {
  return updatePassword(auth.currentUser, novaSenha);
};

export const resetarSenha = async (email) => {
 // console.log("RESET DE SENHA");
  return sendPasswordResetEmail(auth, email);
};

export const registerUserWithEmailAndPassword = async (email, senha) => {
 // console.log("DEBUG registerUserWithEmailAndPassword");
  return createUserWithEmailAndPassword(auth, email, senha);
};

export const logiWithEmailAndPassword = (email, senha) => {
  return signInWithEmailAndPassword(auth, email, senha);
};

export const logout = async () => {
  await signOut(auth);
};

const provider = new GoogleAuthProvider();
const FacebookProvider = new FacebookAuthProvider();

export const signInWithGoogle = () => {
  return signInWithPopup(auth, provider);
};

export const signInWithFacebook = () => {
  signInWithPopup(auth, FacebookProvider)
    .then((result) => {
      const name = result.user.displayName;
      const email = result.user.email;
      const profilePic = result.user.photoURL;
    })
    .catch((error) => {
      //console.log(error);
    });
};
