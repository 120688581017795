import Header from "./components/Header";
import classes from "./App.module.css";
import Timer from "./components/Timer";
import { connect, useSelector } from "react-redux";
import clsx from "clsx";
import { FIREBASE_GET } from "./firebase/metodos";
import store from "./redux/store";
import { setAll } from "./redux/timerSlice";

const App = (props) => {
  const mode = useSelector((state) => state.timer.mode);

  if (props?.timerSlice?.user?.docID) {
    // FIREBASE_GET(props?.timerSlice?.user?.docID).then((DADOS) => {
    //  // console.log("DADOS PEGOS", DADOS);
    //   store.dispatch(setAll(DADOS));
    // });
  }

  return (
    <div className={clsx(classes.container, classes[mode])}>
      <Header />
      <div className={classes.content}>
        <Timer />
      </div>
    </div>
  );
};

const ConnectedApp = connect((state) => {
  return {
    timerSlice: state.timer,
  };
})(App);

export default ConnectedApp;
