import React, { useState } from "react";
import Modal from "./Modal";
import Input from "./Input";
import classes from "./Forgot.module.css";
import Button from "./Button";
import {
  logiWithEmailAndPassword,
  resetarSenha,
  signInWithGoogle,
} from "../firebase/auth";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import store from "../redux/store";

export default function Forgot() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [Erro, setErro] = useState("");
  const [Message, setMessage] = useState("");

  if (store.getState()?.timer?.user?.uid) {
    history.push("../");
  }

  const handleLogin = () => {
    if (email) {
      resetarSenha(email.toLocaleLowerCase())
        .then(() => {
          setMessage(" An email was sent to your email account");
        })
        .catch((error) => {
         // console.log(error);
          setErro("Invalid Email");
          setTimeout(() => {
            setErro("");
          }, 3000);
        });
    }
  };

  return (
    <Modal className={classes.modal}>
      <div className={classes.container}>
        <h2 className={classes.title}>Reset Password</h2>

        {Erro && <h3 className={classes.error}>{Erro}</h3>}
        {Message && (
          <h3 className={classes.message}>
            A password reset email has been sent to your inbox.
          </h3>
        )}
        {Message && (
          <h3 className={classes.message}>
            Please check your email and follow the instructions to reset your
            password.
          </h3>
        )}
        <div className={classes.input}>
          <Input
            placeholder="example@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <Link to="/login">
          <h5 className={classes.link}> Back to Login</h5>
        </Link>

        <div className={classes.button}>
          <Button icon="email" onClick={handleLogin}>
            Send Link
          </Button>
        </div>
      </div>
    </Modal>
  );
}
