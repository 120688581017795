import { connect } from "react-redux";
import Icon from "../Icon";
import { Title, NoData } from "./common";
import classes from "./index.module.css";

const convertMinutesToHours = (minutes) => {
  const hours = minutes / 60;
  const formattedHours = hours.toFixed(2); // Arredonda para duas casas decimais
  return formattedHours.toString(); // Converte o número formatado para uma string
};

const Card = ({ icon, label, value = "--" }) => (
  <div className={classes.card}>
    <div className={classes.cardIcon}>
      <Icon name={icon} size={32} />
    </div>
    <div className={classes.cardTitle}>{value}</div>
    <div className={classes.cardLabel}>{label}</div>
  </div>
);

const CardList = ({ timer }) => {
  return (
    <div>
      {!timer.user.uid && <NoData />}
      <div className={classes.cards}>
        <Card
          icon="schedule"
          label="hours focused"
          value={convertMinutesToHours(timer?.minutesFocused || 0)}
        />
        <Card
          icon="date_range"
          label="days accessed"
          value={timer?.daysAccessed}
        />
        <Card icon="whatshot" label="day streak" value={timer?.dayStreak} />
        <Card icon="cached" label="total pomodoros" value={timer?.round} />
      </div>
    </div>
  );
};

const Summary = (props) => {
  return (
    <div>
      <section className={classes.activity}>
        <Title>Activity Summary</Title>
        <CardList timer={props.timerSlice} />
      </section>
    </div>
  );
};

const ConnectedSummary = connect((state) => {
  return {
    timerSlice: state.timer,
  };
})(Summary);

export default ConnectedSummary;
